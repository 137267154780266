
function Index(props) {

  const { children } = props;

  return (
    <div className='app'>
      No Page
    </div>
  );
}

export default Index;