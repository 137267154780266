import React, { useState } from 'react';
import { PageWrapper } from '../../components';
import productList from './list';
import { Card, CardBody, CardFooter, CardSubtitle, CardTitle, Table, Row, Col } from 'reactstrap';
import { BsGrid, BsListUl } from "react-icons/bs";

function Index() {

  const [listType, setListType] = useState('table');

  const RowData = (item) => {
    return <tr>
      <th scope='row'>{item.product}</th>
      <td>{item.ingredients}</td>
      <td>{item.method}</td>
      <td>{item.application}</td>
    </tr>
  }

  const CardData = (item) => {
    return <Col sm={4}><Card style={{ padding: 10, margin: '5px 0px' }}>
      <CardTitle className='text-center'>{item.product}</CardTitle>
      <CardBody className='text-center'><span className='text-muted'>Active ingredients :</span>{item.ingredients}</CardBody>
      <CardSubtitle className='text-center'><span className='text-muted'>Application :</span> {item.application}</CardSubtitle>
      <CardFooter className='text-center' style={{ fontSize: '.9rem' }}><span className='text-muted'>Method of Analysis :</span> {item.method} </CardFooter>
    </Card></Col>
  }

  return (
    <PageWrapper current='product'>
       {/* <p>
        <img src={require('../../resources/product/product1.jpg')} height={300} width={'25%'} style={{ borderColor: '#e2e2e2', borderStyle: 'solid', borderWidth: '10px 5px 10px 10px' }} />
        <img src={require('../../resources/product/product3.jpg')} height={300} width={'50%'} style={{ borderColor: '#e2e2e2', borderStyle: 'solid', borderWidth: '10px 5px' }} />
        <img src={require('../../resources/product/product2.jpg')} height={300} width={'25%'} style={{ borderColor: '#e2e2e2', borderStyle: 'solid', borderWidth: '10px 5px' }} />
      </p> */}
      <div style={{ margin: '20px 0px' }}>
        <span style={{ margin: '0px 0px', cursor: 'pointer' }}>
          <BsGrid size={25} onClick={() => setListType('card')} color={listType === 'card' ? '#395216 ' : '#cccccc'} />
        </span>
        <span style={{ marginLeft: 10, cursor: 'pointer' }}>
          <BsListUl size={30} onClick={() => setListType('table')} color={listType === 'table' ? '#395216 ' : '#cccccc'} />
        </span>
      </div>
      {listType === 'table' && <div className='product-background-1'>
        <Table striped>
          <thead>
            <tr>
              <th>Product</th>
              <th>Active Ingredient</th>
              <th>Method of Analysis</th>
              <th>Application</th>
            </tr>
          </thead>
          <tbody>
            {productList.map(RowData)}
          </tbody>
        </Table>
      </div>
      }
      {listType === 'card' &&
        <div className='d-flex flex-wrap product-background-1'>
          <Row>{productList.map(CardData)}</Row>
        </div>
      }
      <h5 className='text-center m-4'>WE CAN PROVIDE ALL THE ABOVE PRODUCTS IN POWDER &amp; DC GRADE</h5>
    </PageWrapper>
  );
}

export default Index;