import Container from "./Container";
import { appPath } from '../../App';
import { Link } from "react-router-dom";
import { BiHeadphone, BiPhoneCall, BiMailSend, BiLocationPlus } from 'react-icons/bi'
import { BsWhatsapp } from 'react-icons/bs'

function Footer() {
  return (
    <footer className='portal-footer'>
      <Container>
        <div className='portal-footer-grid'>
          <div>
            <ul>
              {appPath().map(item => <li><Link to={item.path}>{item.label}</Link></li>)}
            </ul>
          </div>
          <div>
            <BiLocationPlus size={50} />
            <div>
              <div>Regd. Office &amp; Factory:</div>
              <div>S. No. 572, Aipur Road, Gundrampally Village</div>
              <div>Chityal Mandal</div>
              <div>Nalgonda Dist. Telangana, India.</div>
            </div>
          </div>
          <div>
            <BiMailSend size={50} />
            <div>adarsh@rege-engredea.com</div>
          </div>
          <div>
            <BiHeadphone size={50} />
            <div>
              <span><BiPhoneCall /> +9390738039</span>
              <span><BsWhatsapp /> +9390738039 </span>
            </div>
          </div>
        </div>
      </Container>
      <div className='footer-sticky-bottom'>ReGe Engredea  |   Copyright © 2012 All rights reserved</div>
    </footer>
  );
}

export default Footer;