import  React from 'react'
import { MdWork } from 'react-icons/md';
import { FaSchool } from 'react-icons/fa';
import { IoMdSchool, IoMdTrash } from 'react-icons/io';
import { BsPersonCheckFill } from 'react-icons/bs';
import CSRBlock from './CSRBlock';

function index() {
  return (
    <React.Fragment>
    <div className='csr-headline'  >
      We pledge to undertake the following as part of our CSR initiatives:
    </div>
    <div className='csr-container'>
      {csrData.map(item => <CSRBlock {...item} />)}
    </div>
    </React.Fragment>
  );
}

export default index;

const csrData = [
  {
    icon: <IoMdSchool />,
    text: "Contribute to the educational institutions by openly welcoming students for realtime training & internship programmes"
  },
  {
    icon: <FaSchool />,
    text: "Undertake small projects for upliftment of immediate communities by donating school books, building infrastructure etc."
  },
  {
    icon: <IoMdTrash />,
    text: "Commitment to protect our immediate environment by following necessary practices in waste disposal management"
  },
  {
    icon: <BsPersonCheckFill />,
    text: "Respect for Human Rights"
  },
  {
    icon: <MdWork />,
    text: "Optimal work environment"
  }
]