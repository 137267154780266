import { Outlet } from "react-router-dom";
import AppNavbar from './AppNavbar';
import Header from './Header';
import Footer from './Footer';
import Brand from './Brand';
import Container from "./Container";

const Index = () => {
  return (
    <>
      <Header>
        <AppNavbar expand="md" />
      </Header>
        <Outlet />
      <Footer />
    </>
  )
};

export default Index;