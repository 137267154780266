
import { Carousel } from 'react-responsive-carousel';
import "react-responsive-carousel/lib/styles/carousel.min.css"; 

function Banner() {
  return (
    <Carousel showThumbs={false} autoPlay={true} infiniteLoop={true}> 
      <div>
        <img src={require('../resources/home/001.jpg')} />
        <p className="legend" style={{fontSize: 22, bottom: 0}}>Active Phyto Ingredients for your most effective formulations"</p>
      </div>
      <div>
      <img src={require('../resources/home/002.jpg')} />
        <p className="legend" style={{fontSize: 22, bottom: 0}}>Destinations can't change overnight but Directions can. </p>
      </div>
      <div>
      <img src={require('../resources/home/001.jpg')} />
        <p className="legend" style={{fontSize: 22, bottom: 0}}>Processing capacity of more than 600MT per month in 3 product streams</p>
      </div>
    </Carousel>
  );
}

export default Banner;