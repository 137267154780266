import React from 'react';
import { PageWrapper } from '../../components';

function Index() {
  return (
    <PageWrapper current='company-profile'>
      <img src={require('../../resources/profile/sourcing.jpeg')}/>
      <h1 id='sourcing' className='display-5'> Sourcing</h1>
      <p>
        Sourcing the right quality of raw material, at the right time & at the right price is imperative for the success of our business. We have developed a strong network with farmers, traders & NGO's over the years for sourcing of our raw materials. We incessantly deliberate on Good Agriculture Practices being followed at our vendors end.
      </p>

      <h1 id='traceability' className='display-5'> Traceability</h1>
      <p>
        We believe that it is our duty to account for traceability of the product in the complete value chain. Traceability is the key to buyer confidence and transparent relationships.
        Backward traceable supply chain - From farm to warehouse
      </p>
      <p>
        Products collected from different sources are distinctly marked & tagged before they reach the warehouse. Though we strive our best to ensure at all times, the level of traceability may change from product to product.
      </p>
      <p>
        Forward traceable supply chain - Warehouse to Production Plant
        All the products entering the warehouse receive an unique GRN (Goods Received Number), which flows into the production system with Batch Manufacturing Records in place, identifying the raw materials, Solvents & exipients (If used any) ensuring traceability till the finished goods are packed.
      </p>

      <h1 id='facility' className='display-5'> Facility</h1>
      <p>
        Our Facility is equipped with modern machinery, each individually designed by our own technical team with a pragmatic approach garnered through immense practical experience, to give the maximum output in a conducive atmosphere.
       </p>
      <p>
        <ul>
          <li>Extractors specially designed for  Turmeric processing & vertical Extractors</li>
          <li>High speed distillation systems for efficient solvent recoveries</li>
          <li>Separation & Drying systems include a variety of machines like Centrifuge, ANFD, Filter Press, Tray Dryers, Rotary Vacuum Dryers, Spray Dryer</li>
          <li>Powder Processing systems of GMP models in clean rooms</li>
          <li>Closed conduit systems (mechanical & pneumatic) for material transfers</li>
        </ul>
        <img src={require('../../resources/profile/facility1.jpeg')} height={300} style={{borderColor: '#e2e2e2', borderStyle:'solid', borderWidth: '10px 5px 10px 10px'}}/>
        <img src={require('../../resources/profile/facility2.jpeg')} height={300} style={{borderColor: '#e2e2e2', borderStyle:'solid', borderWidth: '10px 5px'}}/>
        <img src={require('../../resources/profile/facility3.jpeg')} height={300} style={{borderColor: '#e2e2e2', borderStyle:'solid', borderWidth: '10px 10px 10px 5px'}}/>
      </p>
      <p>
        Our material transfer systems are built such that there is no human intervention once the raw material is charged into the Extractor till the active ingredient is isolated, purified, processed and packed.
      </p>
      <h1 id='quality' className='display-5'> Quality Assurance & Quality Check</h1>
      <p>
        Our quality assurance team works seamlessly to ensure that all necessary precautions are taken during each step of extraction, isolation, purification & powder processing. The standard operating procedures are designed & adapted to produce a high quality product.
      </p>
      <p>
      <img src={require('../../resources/profile/quality1.jpeg')} height={300} style={{borderColor: '#e2e2e2', borderStyle:'solid', borderWidth: '10px 5px 10px 10px'}}/>
      <img src={require('../../resources/profile/quality2.jpeg')} height={300} style={{borderColor: '#e2e2e2', borderStyle:'solid', borderWidth: '10px 5px'}}/>
      <img src={require('../../resources/profile/quality3.jpeg')} height={300} style={{borderColor: '#e2e2e2', borderStyle:'solid', borderWidth: '10px 5px'}}/>
      <img src={require('../../resources/profile/quality4.jpeg')} height={300} style={{borderColor: '#e2e2e2', borderStyle:'solid', borderWidth: '10px 10px 10px 5px'}}/>

      </p>
      <p>
        Our in house lab is equipped with hitech analytical machines like HPLC, Headspace GC etc. In line product Samples & finished product Samples are tested for quality in terms of Assay, moisture, particle size, heavy metal & microbial assay etc.
      </p>
      <h1 id='certifications' className='display-5'> Certified by</h1>
      <p>
        Kosher and Halal, awaiting ISO 9001:2008 &amp; HACCP
      </p>
    </PageWrapper>
  );
}

export default Index;