import { Breadcrumb, BreadcrumbItem } from 'reactstrap';

const Crumb = (item) => <BreadcrumbItem key={`crumb-path-${item.path}`} href={item.path} tag={item.current ? 'span' : 'a'}>{item.label}</BreadcrumbItem>

const AppBreadcrumb = ({ data }) => {
  return (
    <div className='app-breadcrumb'>
      <div className='container'>
        <Breadcrumb listTag="div" tag='div'>
          {data.map(Crumb)}
        </Breadcrumb>
      </div>
    </div>
  )
};

export default AppBreadcrumb;