import React from 'react';
import AppBreadcrumb from './AppBreadcrumb';
import Container from '../pages/Layout/Container';
import { appPath } from '../App'

function PageWrapper({ current, children }) {
  return (
    <React.Fragment>
      <AppBreadcrumb data={appPath(current)} />
      <Container style={{minHeight: '65vh'}}>
        {children}
      </Container>
    </React.Fragment>
  );
}

export default PageWrapper;