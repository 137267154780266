function Header({ children }) {
  return (
    <header className='portal-header'>
      <div className='container'>
        {children}
      </div>
    </header>
  );
}

export default Header;