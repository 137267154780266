import { useEffect } from "react";
import { BrowserRouter, Routes, Route, Re } from "react-router-dom";
import './App.css';
import 'bootstrap/dist/css/bootstrap.min.css';
import { About, Contact, Home, Product, Profile, NoPage, Layout } from './pages'
import { useNavigate } from "react-router-dom";

function App() {

  function Redirect({ to }) {
    let navigate = useNavigate();
    useEffect(() => {
      navigate(to);
    });
    return null;
  }

  return (
    <BrowserRouter>
      <Routes>
        <Route path="/" element={<Layout />}>
          <Route index element={<Home />} />
          {
            paths.map(item => <Route key={`route-path-${item.path}`} path={`/${item.path}`} element={item.element} />)
          }
          <Route path="*" element={<NoPage />} />
        </Route>
      </Routes>
    </BrowserRouter>
  );
}

export default App;


const paths = [
  {
    label: 'Home',
    path: 'home',
    element: <Home />
  },
  {
    label: 'Product',
    path: 'product',
    element: <Product />,
    parent: 'home'
  },
  {
    label: 'Company Profile',
    path: 'company-profile',
    element: <Profile />,
    parent: 'home'
  },
  {
    label: 'About',
    path: 'about',
    element: <About />,
    parent: 'home'
  },
  {
    label: 'Contact',
    path: 'contact',
    element: <Contact />,
    parent: 'home'
  }
];

const findParent = (item) => {
  let parent = paths.filter(pitem => item.parent === pitem.path);
  if (parent.length === 1) {
    return parent[0];
  }
  return undefined;
}

const appPath = (current) => {
  if (current) {
    let crumbs = [];
    let level1;
    let level2;
    const item = paths.filter(item => item.path === current)[0];
    item.current = true;
    crumbs.push(item);

    if (item.parent) {
      level1 = findParent(item);
      crumbs.push(level1);
    }
    if (level1 && level1.parent) {
      level2 = findParent(level1);
      if (level2) {
        crumbs.push(level2);
      }
    }
    return crumbs.reverse();
  }
  return paths;
}

export {
  appPath
}