import React from 'react';
import { PageWrapper } from '../../components';

function Index() {
  return (
    <PageWrapper current='about'>
      <h1 className='display-5'> About Us </h1>
      <blockquote className="blockquote">
        <p className="mb-0">"Destinations can't change overnight but Directions can"</p>
      </blockquote>
      <p>
        In the summer of 2017 we started building our dream into a reality. ReGe Engredea Pvt. Ltd. evolved from the years of hardship & labour, gaining the growth momentum with each passing day for more than a decade.
      </p>
      <p className='lead'> The Beginning (2005-2017) </p>
      <p>
        Founded by Late Dr. A. N. Patwari under the stewardship of Adarsh Patwari, M. Tech. (Chemical Engineering) Managing Director and Anup Patwari, B. Sc. (Microbiology), Director.
        We began our beautiful journey way back in 2005 under the able guidance of Dr. A. N. Patwari, a renowned scientist from Indian Institute of Chemical Technology, who was a pillar of strength for us. For more than a decade we established ourselves in the manufacturing of herbal /spice extracts. We worked in various facilities in & around Hyderabad on lease basis & successfully managed to produce products like Curcuminoids (Turmeric Extract), Boswellic Acid (Boswellia serrate dry extract) & Garcinia cambogia extracts in huge volumes. Our limitation was to produce only one product at a time as these facilities had only single stream & they being leased units we couldn't do much about it. Nevertheless, whatever we produced, it was in huge volumes & we created our mark in the industry for each of the product. All our previous facilities have had various certifications like Star K KOSHER, ISO & HACCP, AYUSH, FDA Registration etc. & we have successfully faced tough audits of our own foreign clients & also those of our domestic merchant exporters. Initially we began with contract manufacturing business model & gradually with our own products we achieved a turnover of about one million USD for three consecutive years.
      </p>
      <p>
        With this solid foundation, we have built our own facility, which has three product streams which can be used simultaneously. Spread over a land of about five acres on the outskirts of Hyderabad, it is a state of art facility, with robust systems in place  which evolved out of our rich & practical experience.
      </p>
    </PageWrapper>
  );
}

export default Index;