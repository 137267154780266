
export default  [
  { product: "Curcuma longa(Turmeric Extract)", ingredients: "Curcuminoids", method: "By HPLC", application: "Antioxidant, Anti-inflamatory, Anti-cancer" },
  { product: "Plectranthus barbatus (Coleus forskohlii Extract)", ingredients: "Forskolin", method: "By HPLC", application: "Heart disorders" },
  { product: "Garcinia cambogia Extract", ingredients: "Hydroxy Citric Acid (HCA) (single, double & triple salts) ", method: "By HPLC", application: "Weight Loss Management" },
  { product: "Boswellia serrate dry Extracts", ingredients: "Boswellic Acid AKBA", method: "By GravimetryBy HPLC ", application: "Anti-inflamatory, Anti-arthritic" },
  { product: "Withania somnifera (Ashwagandha) ", ingredients: "Withanoloids  Alkaloids", method: "By HPLC By Gravimetry", application: "Antioxidant" },
  { product: "Green Coffee Bean Extract", ingredients: "Chlorogenic Acid", method: "By HPLC", application: "Weight Loss Management" }, { product: "Caffeine", ingredients: "Caffeine", method: "By HPLC", application: "Wakefulness, Mental alertness" },
  { product: "Andrographis paniculata (Kalmegh)", ingredients: "Andrographolides", method: "By HPLC", application: "Immunostimulant" },
  { product: "Commiphora mukul (Guggul)", ingredients: "Guggulsterones", method: "By HPLC", application: "Weight Loss Management, Anti-arthritic, Atherosclerosis" },
  { product: "Bacopa monnieri (Brahmi)", ingredients: "Bacosides", method: "By HPLC", application: "Memory enhancement" },
  { product: "Cassia angustifoli (Senna)", ingredients: "Sennosides", method: "By HPLC", application: "Laxative" },
  { product: "Asparagus racemosus (Shatawari)", ingredients: "Saponins", method: "By Gravimetry", application: "Female reproductive system, Respiratory System, Digestive System" },
  { product: "Asphaltum (Shilajit)", ingredients: "Fulvic Acid", method: "By UV", application: "Antioxidant, Healthy Ageing" },
  { product: "Fenugreek", ingredients: "4-Hydroxy Isoleucine Saponins ", method: "By HPLC By UV", application: "Antioxidant, Diabetes" },
  { product: "Tribulus terristis (Gokhru)", ingredients: "Saponins", method: "By Gravimetry", application: "Enhance sexual health" },
  { product: "Glycyrrhiza glabra (Liquorice /Mulethi)", ingredients: "Glycyrrhizinic Acid", method: "By HPLC", application: "Heartburn, Indigestion" },
  { product: "Gymnema sylvestre", ingredients: "Gymnemic Acid", method: "By HPLC", application: "Diabetes, Weight Loss Management" },
  { product: "Banaba", ingredients: "Banaba", method: "By HPLC", application: "Weight Loss Management" },
  { product: "Black Pepper", ingredients: "Piperine", method: "By HPLC", application: "Antioxidant, Anti-cancer" },
  { product: "Emblica officinalis (Amla)", ingredients: "TanninsAscorbic Acid", method: "By UVBy HPLC ", application: "Health Ageing, Diuretic, Cardiotonic" },
]