import React, { useState } from 'react';
import {
  Link,
  useMatch,
  useResolvedPath
} from "react-router-dom";

import { HashLink } from 'react-router-hash-link';


import {
  Collapse,
  Navbar,
  NavbarToggler,
  NavbarBrand,
  Nav,
  NavItem,
  NavLink,
  UncontrolledDropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
} from 'reactstrap';

function LinkTo({ children, href, ...props }) {
  let resolved = useResolvedPath(href);
  let match = useMatch({ path: resolved.pathname, end: true });
  return (<Link style={{ marginTop: 10 }} to={href} className={match ? 'active' : undefined} {...props}>{children}</Link>)
}

const AppNavbar = (args) => {
  const toggle = () => setIsOpen(!isOpen);
  const [isOpen, setIsOpen] = useState(false);

  return (

    <Navbar {...args} className=''>
      <NavbarBrand href="/" className='brand'><img src={require('../../resources/layout/logo-head.jpg')} height={60} /></NavbarBrand>
      <NavbarToggler onClick={toggle} />
      <div className='w-75'/>
      <Collapse isOpen={isOpen} navbar>
        <Nav className="ml-auto" navbar>
          <NavItem>
            <NavLink href="/product">Products</NavLink>
          </NavItem>
          <UncontrolledDropdown nav inNavbar>
            <DropdownToggle nav caret>
              Company Profile
            </DropdownToggle>
            <DropdownMenu end>
              <DropdownItem tag="a" href="/about">About</DropdownItem>
              <DropdownItem><HashLink to="/company-profile#sourcing">Sourcing</HashLink></DropdownItem>
              <DropdownItem><HashLink to="/company-profile#traceability">Traceability</HashLink></DropdownItem>
              <DropdownItem><HashLink to="/company-profile#facility">Facility</HashLink></DropdownItem>
              <DropdownItem><HashLink to="/company-profile#quality">Quality</HashLink></DropdownItem>
              <DropdownItem><HashLink to="/company-profile#certifications">Certifications</HashLink></DropdownItem>
            </DropdownMenu>
          </UncontrolledDropdown>
          <NavItem>
            <NavLink href="/contact">Contact</NavLink>
          </NavItem>
        </Nav>
      </Collapse>
    </Navbar>
  )
};

export default AppNavbar;
