import React, { useState } from 'react'
import { PageWrapper } from "../../components";
import { Button, Form, FormGroup, Label, Input, Row, Col, Modal, ModalBody, ModalHeader } from 'reactstrap';
import { BiPhoneCall } from 'react-icons/bi'
import { BsWhatsapp } from 'react-icons/bs'

function Index() {

  const [form, setForm] = useState({
    name: '',
    email: '',
    company: '',
    message: ''
  })

  const [message, setMessage] = useState({header: '', message: ''});
  const [visible, setVisibility] = useState(false);

  const handleChange = (e) => {
    const obj = { [e.currentTarget.name]: e.currentTarget.value };
    setForm(f => ({
      ...f,
      ...obj
    }));
  }

  const isFormValid = () => {
    const len = Object.keys(form).map(item => form[item].length > 0).filter(item => item === false);
    return len.length === 0;
  }

  const handleSubmit = () => {
    if(isFormValid()) {
      setMessage({header: 'Thank you', message: 'We have received your message and will revert back shortly'})
      setForm({
        name: '',
        email: '',
        company: '',
        message: ''
      });
    } else {
      setMessage({header: 'Incomplete Details', message: 'Entered information seems to be Incomplete or Incorrect'})
    }
    setVisibility(true);
    return false;
  }

  return (
    <PageWrapper current='contact'>
      <h4 id='certifications' className='display-8 text-center my-4'> Please get in touch, we would like to hear from you</h4>
      <Row>
        <Col sm={7}>
          <FormGroup row>
            <Label sm={2} for="name">Full Name</Label>
            <Col>
              <Input sm={10} type="text" name="name" id="name" placeholder="Full Name" bsSize="md" value={form.name} onChange={handleChange} />
            </Col>
          </FormGroup>

          <FormGroup row>
            <Label sm={2} for="email">Email</Label>
            <Col>
              <Input sm={10} type="email" name="email" id="email" placeholder="Email" bsSize="md" value={form.email} onChange={handleChange} />
            </Col>
          </FormGroup>

          <FormGroup row>
            <Label sm={2} for="company">Company</Label>
            <Col>
              <Input sm={10} type="text" name="company" id="company" placeholder="Company" bsSize="md" value={form.company} onChange={handleChange} />
            </Col>
          </FormGroup>
          <FormGroup row>
            <Label sm={2} for="message">Message</Label>
            <Col>
              <Input sm={10} type="textarea" name="message" id="message" value={form.message} onChange={handleChange} />
            </Col>
          </FormGroup>
          <FormGroup row>
            <Label sm={2} for="button"></Label>
            <Col>
              <Button color='success' type='submit' onClick={handleSubmit}>Submit</Button>
            </Col>
          </FormGroup>
        </Col>

        <Col>
          <div className='contact-details border-left'>
            <div className=''>
              <b>Regd. Office &amp; Factory:</b><br />

            S. No. 572, Aipur Road<br />
            Gundrampally Village, Chityal Mandal<br />
            Nalgonda Dist. Telangana, India.<br />
            </div>
            <div>
              <span><BiPhoneCall /> +9390738039</span>
              <span className='p-3' />
              <span><BsWhatsapp /> +9390738039 </span>
            </div>

            <div>adarsh@rege-engredea.com</div>
          </div>
        </Col>
      </Row>
      <Row>
        <div className='pt-4'>
          <div className="header-pull-down">
            <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3810.699120791993!2d78.98180681537434!3d17.233379488171106!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3bcb165015555555%3A0x43c8e325d7ec3d54!2sReGe%20Engredea%20Pvt%20Ltd!5e0!3m2!1sen!2sin!4v1661404406708!5m2!1sen!2sin"
              width="100%" height="240" frameborder="0" scrolling="no" marginheight="0" marginwidth="0" style={{ border: 0 }} allowfullscreen="" loading="lazy" referrerpolicy="no-referrer-when-downgrade"></iframe>
            <div style={{ color: '#0000FF', textAlign: 'left' }}><a href="https://goo.gl/maps/iK16911HhqsYrSwE9"
              style={{ color: '#0000FF', textAlign: 'left', fontSize: '.8rem' }}>View Larger Map</a></div>
          </div>
        </div>
      </Row>

      <Modal isOpen={visible}>
        <ModalHeader toggle={() => setVisibility(false)}>
          {message.header}
        </ModalHeader>
        <ModalBody>
          {message.message}
        </ModalBody>
      </Modal>
    </PageWrapper>
  );
}

export default Index;