import React from 'react';
import { Banner } from '../../components';
import { FiEye, FiTarget } from "react-icons/fi";
import CSR from './Partial/CSR';
import Container from '../Layout/Container';

function Index() {
  return (
    <React.Fragment>
      <Container>
        <Banner />

        <div className='lead-container'>
          <div className='lead-title'>ReGe Engredea Pvt Ltd </div>
        </div>
        <p>ReGe Engredea Pvt Ltd (REPL) is an established company catering to the ingredient buyers in the nutraceutical, pharmaceutical, food and cosmetic industry. With
        overseas network in USA, UK &amp; NZ – Australia, ReGe Engredea Pvt Ltd (REPL) is emerging as a preferred vendor for dietary supplement manufacturers across the
        globe on account of timely supply, strict adherence to quality norms and a committed effort towards 100% customer satisfaction. We believe that Natural Products manufacturing is not just a science but an Art
          which can be mastered only by practice. We strive to master every niche of this art in each passing day. Sourcing of the right raw material is critical in production of natural ingredients &amp; we have over the years developed the expertise in our sourcing.</p>

        <p>
          We believe that Natural Products manufacturing is not just a science but an Art which can be mastered only by practice. We strive to master every niche of this art
          in each passing day. Sourcing of the right raw material is critical in production of natural ingredients &amp; we have over the years developed the expertise in our
          sourcing.
        </p>
        {/* Vision & Mission Statement  */}

        <div className='vision-mission-container'>
          <div className='block'>
            <FiEye size={80} style={{ color: '#395216' }} />
            <div className='vision-mission-title'>vision</div>
            <div className='vision-mission'>
              To offer the natural ingredients at affordable costs to a common man & have a total reach out globally. Maintain a healthy ecological balance & give back what we take from nature.
          </div>
          </div>
          <div className='divider' />
          <div className='block'>
            <FiTarget size={60} style={{ color: '#395216' }} />
            <div className='vision-mission-title'>Mission</div>
            <div className='vision-mission'>
              To produce active biomolecules, isolated from natural sources & purified in robust systems, handcrafted to suit the customer requirements in terms of quality & cost.
          </div>
          </div>
        </div>

        {/* CSR Initiatives  */}
        <CSR />
      </Container>
    </React.Fragment>
  );
}

export default Index;